<template>
  <div class="m-view-hero">
    <van-button class="hero-swiper-next navigation-btn img-btn">
      <img src="@/assets/img/swiper-arrow.png" />
    </van-button>
    <van-button class="hero-swiper-pre navigation-btn img-btn"
      ><img src="@/assets/img/swiper-arrow.png"
    /></van-button>
    <div class="swiper swiper-container" id="hero-swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in slideCount" :key="item">
          <img
            :src="
              require(`@/assets/img/hero/${isMobile ? item + '-m' : item}.png`)
            "
            class="hero-pic"
          />
        </div>
      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-scrollbar"></div>
    </div>
  </div>
</template>

<script setup>
import Swiper, { Navigation, Pagination } from "swiper";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";

const slideCount = 10;
const isMobile = ref(false);
onMounted(() => {
  isMobile.value = document.body.clientWidth <= 768;
  const swiper = new Swiper("#hero-swiper", {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    loop:true,
    speed: 400,
    autoplay: {
      delay: 1000,
    },
    navigation: {
      nextEl: ".hero-swiper-next",
      prevEl: ".hero-swiper-pre",
    },
  });
});
const router = useRouter();
</script>

<style lang="less">
.m-view-hero {
  position: relative;
  flex-grow: 1;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  background-image: url("@/assets/img/hero/bg.jpg");
  background-color: #fff;
  background-size: 100% 100%;

  #hero-swiper {
    height: 100%;

    .swiper-slide {
      width: 100vw;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;

      .hero-pic {
        width: 88%;
        position: absolute;
        top: 0;
        left: 2%;
        z-index: 0;
        transform: translateY(0%);
      }
    }
  }

  .navigation-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;

    img {
      width: 72px;
    }

    &:hover {
      img {
        width: 80px;
      }
    }
  }

  .hero-swiper-next {
    right: 54px;
    transform: rotateY(180deg) translateY(-50%);
  }

  .hero-swiper-pre {
    left: 54px;
  }
}

@media screen and (max-width: 768px) {
  .m-view-hero {
    #hero-swiper {
      overflow: hidden;
      .swiper-slide {
        overflow: hidden;
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.8)
        );
        .hero-pic {
          width: 130vw;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .navigation-btn {
      position: absolute;
      top: 70%;
      z-index: 9;
      img {
        width: 78px;
      }
    }

    .hero-swiper-next {
      right: 3.73%;
      transform: rotateY(180deg) translateY(-50%);
    }

    .hero-swiper-pre {
      left: 6.75%;
    }
  }
}
</style>
