<template>
  <Dialog
    :show="show"
    @close="$emit('close')"
    :background-image="require('@/assets/img/dialog-bg-3.png')"
  >
    <div class="congrats-wrapper">
      <p class="title">{{ modal.title }}</p>
      <div
        class="content"
        v-html="modal.conent"
        :style="{ 'text-align': modal.align }"
      ></div>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, watch, nextTick, toRef, toRefs } from 'vue';
import Dialog from '@/components/Dialog.vue';
import useModal from '@/store/modules/modal';
const emit = defineEmits(['close']);
const modal = useModal();

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
});
const { show } = toRefs(props);
watch(
  () => modal.conent,
  (old, v) => {
    $('.ui.popup-tarot').popup({
      inline: true,
    });
  }
);
</script>

<style lang="less">
#app .my-dialog {
  overflow: unset !important;
}
.m-view-congrats {
  width: 100vw;
  height: 100%;
  background-image: url('@/assets/img/dialog-bg.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.congrats-wrapper {
  width: 574px;
  padding: 40px 30px 30px 30px;
  box-sizing: content-box;
  .title {
    font-size: 30px;
    font-weight: 400;
    color: #d8d5d0;
    background: linear-gradient(0deg, #f9f6c3 0%, #e7a952 100%);
    margin-bottom: 38px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }
  .content {
    line-height: 30px;
    font-family: auto;
    font-weight: 500;
    color: #d8d5d0;
    font-size: 22px;
    text-align: center;
    margin-bottom: 12px;
  }
}
.congrats-dialog-footer {
  display: flex;
  justify-content: space-around;
  .van-button {
    width: 241px;
    font-size: 20px;
    padding: 0;
  }
}
</style>
