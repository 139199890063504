<template>
  <div class="m-view-intro">
    <van-button style="top: 35%;" class="swiper-next navigation-btn img-btn">
      <img src="@/assets/img/swiper-arrow.png" />
    </van-button>
    <van-button style="top: 35%;" class="swiper-pre navigation-btn img-btn"
      ><img src="@/assets/img/swiper-arrow.png"
    /></van-button>
    <div class="swiper swiper-container" id="intro-swiper">
      <p v-if="isMobile" class="title">Game Features</p>
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in 5" :key="item">
          <div class="mask"></div>
          <img :src="require(`@/assets/img/intro/${isMobile ? item + '-m' : item}.png`)" />
          <p class="intro-text">
            The arrival of death may not necessarily be accompanied by the sound
            of the funeral bell, listen to the sound of the waves!
          </p>
          </div>
      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-scrollbar"></div>
    </div>
  </div>
  <mFooter :absolute="true"></mFooter>
</template>

<script setup>
import Swiper, { Navigation, Pagination, EffectCoverflow } from "swiper";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import mFooter from "@/layout/footer.vue";

const isMobile = ref(false);
onMounted(() => {
  isMobile.value = document.body.clientWidth <= 768;
  const swiper = new Swiper("#intro-swiper", {
    modules: [Navigation, Pagination, EffectCoverflow],
    slidesPerView: 3,
    grabCursor: true,
    slidesPerView: "auto",
    effect: "coverflow",
    coverflowEffect: {
      rotate: 0,
      stretch: isMobile.value ? "70%" : "50%",
      depth: 270,
      modifier: 1,
      slideShadows: true,
    },
    loop: true,
    spaceBetween: 30,
    centeredSlides: true,
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-pre",
    },
  });
});

const router = useRouter();
</script>

<style lang="less">
.m-view-intro {
  position: relative;
  flex-grow: 1;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  background-image: url("@/assets/img/intro/bg.png");
  background-size: 100% 100%;

  .swiper {
    width: 80%;
    height: 70%;

    .swiper-wrapper {
      align-items: center;
    }

    .swiper-slide {
      width: 829px;
      height: 458px;
      position: relative;
      display: flex;
      justify-content: center;
      visibility: hidden;
      flex-direction: column;
      align-items: center;

      .mask {
        width: 99%;
        height: 97%;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.7);
        transform: translate(-50%, -50%);
      }

      img {
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      .intro-text {
        visibility: hidden;
        width: 786px;
        font-size: 20px;
        font-weight: 400;
        color: #ede7dc;
        line-height: 50px;
        text-shadow: 0px 2px 3px #000000;
        position: absolute;
        bottom: 0;
        transform: translateY(calc(100%));
      }
    }

    .swiper-slide-visible {
      visibility: visible;
    }

    .swiper-slide-prev,
    .swiper-slide-next {
      opacity: 0.9;

      //   width: 672px;
      //   height: 372px;
    }

    .swiper-slide-active {
      .mask {
        visibility: hidden;
      }

      .intro-text {
        visibility: visible;
      }

      &::after {
        content: "";
        position: absolute;
        z-index: 0;
        background-image: url("@/assets/img/intro/top-bg.png");
        background-size: 100% auto;
        width: 100%;
        height: 355px;
        transform: translateY(-78%);
      }

      &::before {
        content: "";
        position: absolute;
        z-index: 0;
        background-image: url("@/assets/img/intro/bottom-line.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 28px;
        bottom: 0;
        transform: translateY(100%);
      }
    }
  }

  .navigation-btn {
    position: absolute;
    top: 46.48%;
    z-index: 9;

    img {
      width: 72px;
    }

    &:hover {
      img {
        width: 80px;
      }
    }
  }

  .swiper-next {
    right: 54px;
    transform: rotateY(180deg) translateY(-50%);
  }

  .swiper-pre {
    left: 54px;
  }
}

@media screen and (max-width: 768px) {
  .m-view-intro {
    background-image: url("@/assets/img/intro/bg-m.png");
    background-size: 100% auto;
    background-position-y: center;
    height: 100%;
    .swiper {
      width: 90%;
      height: 100%;

      .title {
        text-align: center;
        font-size: 36px;
        font-weight: 400;
        line-height: 57px;
        background: linear-gradient(0deg, #f9f6c3 0%, #e7a952 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transform: translateY(150%);
      }

      .swiper-wrapper {
        align-items: center;
        height: fit-content;
        top: 10%;
      }

      .swiper-slide {
        width: 497px;
        height: 883px;

        .mask {
        }

        img {
        }

        .intro-text {
          visibility: hidden;
          width: 90vw;
          font-size: 20px;
          line-height: 40px;
          text-shadow: 0px 2px 3px #000000;
          transform: translateY(calc(100% + 76px));
        }
      }

      .swiper-slide-visible {
        visibility: visible;
      }

      .swiper-slide-active {
        .mask {
          visibility: hidden;
        }

        .intro-text {
          visibility: visible;
        }
      }
    }

    .navigation-btn {
      display: none;
    }
  }
}
</style>
