import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'

import en_us from './en_us.json'
import zh_cn from './zh_cn.json'

export const SUPPORT_LOCALES = [
    'zh_cn',
    'en_us'
]

export function setupI18n(options = { locale: "zh_cn" }) {
    const i18n = createI18n(options)
    setI18nLanguage(i18n, options.locale)
    return i18n
}

export function setI18nLanguage(i18n, locale) {
    if (i18n.mode === 'legacy') {
        i18n.global.locale = locale
    } else {
        i18n.global.locale.value = locale
    }
    document.querySelector('html').setAttribute('lang', locale)
}

// export async function loadLocaleMessages(i18n, locale) {
//     // load locale messages with dynamic import
//     const messages = await import(`./${locale}.json`)
//     // set locale and locale message
//     i18n.global.setLocaleMessage(locale, messages.default)

//     return nextTick()
// }

const _getCookie = function (key) {
    var value = ''
    var cookies = document.cookie
    var cookieArray = cookies.split(";")
    for (var i = 0; i < cookieArray.length; i++) {
        var cookie = cookieArray[i].trim()
        if (cookie.indexOf(key + "=") == 0) {
            value = cookie.replace(key + "=", '')
            break
        }
    }
    return value
}

const i18n = setupI18n({
    legacy: false,
    globalInjection: true,
    fallbackLocale: 'en_us',
    locale: _getCookie('nova_lang') || 'en_us',
    messages: {
        zh_cn,
        en_us
    }
})

export default i18n

