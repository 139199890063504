<template>
  <van-dialog
    v-model:show="props.show"
    title=""
    show-cancel-button
    class-name="my-dialog"
    :showConfirmButton="false"
    :showCancelButton="false"
    :style="{ backgroundImage: `url(${backgroundImage})` }"
    :closeOnClickOverlay="true"
    :beforeClose="handleClose"
    v-bind="$attrs"
  >
    <van-button class="close-btn img-btn" @click="handleClose">
      <img src="@/assets/img/dialog-close-icon.png" />
    </van-button>

    <div class="dialog-content">
      <slot></slot>
    </div>
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </van-dialog>
</template>

<script setup>
import { ref } from 'vue';
// const show = ref(true);

const emit = defineEmits(['close']);

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  backgroundImage: {
    type: String,
    default: require('@/assets/img/dialog-bg-1.png'),
  },
});

const handleClose = () => {
  emit('close');
};
</script>

<style lang="less">
#app .my-dialog {
  box-sizing: border-box;
  width: fit-content;
  height: fit-content;
  background-color: initial;
  background-size: 100% 100%;
  border: none;

  .close-btn {
    position: absolute;
    right: 6%;
    top: 7%;
    img {
      height: 25px;
      width: 25px;
    }
  }

  .dialog-title-logo {
    width: 222px;
    margin-bottom: 33px;
  }
  .dialog-content {
    font-family: SourceHanSerif;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
  }
  .dialog-footer-btn-group {
    display: flex;
    text-align: center;
  }
}
</style>
