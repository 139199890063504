import { Aptos, AptosConfig, Network } from "@aptos-labs/ts-sdk"

export const aptosClient = (network) => {
  if (network?.name === Network.DEVNET) {
    return DEVNET_CLIENT
  } else if (network?.name === Network.TESTNET) {
    return TESTNET_CLIENT
  } else if (network?.name === Network.MAINNET && !process.env.VUE_APP_MODE == 'production') {
    throw new Error("Please use devnet or testnet for testing")
  } else {
    const MAINNET_CONFIG = new AptosConfig({
      network: Network.MAINNET,
    })
    return new Aptos(MAINNET_CONFIG)
  }
}

// Devnet client
export const DEVNET_CONFIG = new AptosConfig({
  network: Network.DEVNET,
})
export const DEVNET_CLIENT = new Aptos(DEVNET_CONFIG)

// Testnet client
export const TESTNET_CONFIG = new AptosConfig({ network: Network.TESTNET })
export const TESTNET_CLIENT = new Aptos(TESTNET_CONFIG)

export const isSendableNetwork = (
  connected,
  networkName,
) => {
  return connected && !isMainnet(connected, networkName)
}

export const isMainnet = (
  connected,
  networkName,
) => {
  return connected && networkName === Network.MAINNET
}
