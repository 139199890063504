<template>
  <div class="m-view-result">
    <img src="@\assets\img\result-top.png" alt="" class="result-top" />
    <!--  <video src="@\assets\video\card_show_alpha.mp4" autoplay class="card-video" loop muted></video> -->
    <!-- 塔罗牌展示 -->
    <div class="card-show" id="cardshowbox">
      <div class="choose-card-ctn" id="chooseCard1">
        <div class="card-text-up" id="timeState">PAST</div>
        <img
          id="cardPic1"
          :src="setSrc(1)"
          alt="card image miss"
          class="choose-card"
        />
        <div class="card-text-down" id="cardName">
          {{ cardsFromParent.past.name }}
        </div>
      </div>
      <div class="choose-card-ctn" id="chooseCard2">
        <div class="card-text-up" id="timeState">PRESENT</div>
        <img
          id="cardPic2"
          :src="setSrc(2)"
          alt="card image miss"
          class="choose-card"
        />
        <div class="card-text-down" id="cardName">
          {{ cardsFromParent.present.name }}
        </div>
      </div>
      <div class="choose-card-ctn" id="chooseCard3">
        <div class="card-text-up" id="timeState">FUTURE</div>
        <img
          id="cardPic3"
          :src="setSrc(3)"
          alt="card image miss"
          class="choose-card"
        />
        <div class="card-text-down" id="cardName">
          {{ cardsFromParent.future.name }}
        </div>
      </div>
    </div>
    <!-- <img id="cardImage" :src="setSrc()" alt="" class="card-video" /> -->

    <div class="result-content" id="resCont" style="white-space: pre-line">
      <vuetyped
        :strings="[divineContent]"
        v-if="showContentType"
        :loop="false"
        :smart-backspace="false"
        :typeSpeed="5"
        :showCursor="false"
        @onStringTyped="handleType"
        @onComplete="showAgain = true"
      >
        <div class="typing content" ref="contentRef"></div>
      </vuetyped>

      <!-- <van-button class="back" @click="$emit('close')">Back</van-button> -->
    </div>
    <div class="msg-text" v-if="showWaitMsg">
      In the process of divination ...
    </div>
    <van-button class="back" @click="handleCloseAgain" v-if="showAgain"
      >Again</van-button
    >
  </div>
</template>

<script setup>
import { Deck } from '@/utils/deck.js';
import { watch, onMounted, ref, getCurrentInstance } from 'vue';
import { useCookies } from 'vue3-cookies';
import http from '../utils/http';
import i18n from '../i18n';

const emit = defineEmits(['close']);
const contentRef = ref();
const divineContent = ref('');
const showWaitMsg = ref(false);
const getData = ref(false);
const showAgain = ref(false);
const { cookies } = useCookies();
const showContentType = ref(false);
const { proxy } = getCurrentInstance();
const { cardsFromParent, quertionFromParent } = defineProps([
  'cardsFromParent',
  'quertionFromParent',
]);

var $CardEle1 = null;
var $CardEle2 = null;
var $CardEle3 = null;

var $ResultContentEle = null;

var getCardData = false;
var dowithCard = false;
var cardsPos = ['upright', 'upright', 'upright'];

watch(
  () => proxy.$global.id,
  (val, old) => {
    handleCloseAgain();
  }
);

const handleType = () => {
  //console.log(contentRef.value.scrollHeight, contentRef.value.offsetHeight);
  if (contentRef.value.scrollHeight > contentRef.value.offsetHeight) {
    contentRef.value.scrollTop = contentRef.value.scrollHeight;
  }
};

onMounted(() => {
  divine(); //开始调占卜接口

  $CardEle1 = document.getElementById('chooseCard1');
  $CardEle2 = document.getElementById('chooseCard2');
  $CardEle3 = document.getElementById('chooseCard3');

  $CardEle1.style.transform = 'translateY(30px)';
  //$CardEle2.style.transform = "translateX(-25%)";
  $CardEle3.style.transform = 'translateY(30px)';

  if (dowithCard == false && getCardData == true) {
    var $cardImgEle1 = document.getElementById('cardPic1');
    var $cardImgEle2 = document.getElementById('cardPic2');
    var $cardImgEle3 = document.getElementById('cardPic3');

    if (cardsPos[0] == 'upright') {
      $cardImgEle1.style.transform = 'translateX(-50%) rotateX(0deg)';
    } else {
      $cardImgEle1.style.transform = 'translateX(-50%) rotateX(180deg)';
    }

    if (cardsPos[1] == 'upright') {
      $cardImgEle2.style.transform = 'translateX(-50%) rotateX(0deg)';
    } else {
      $cardImgEle2.style.transform = 'translateX(-50%) rotateX(180deg)';
    }

    if (cardsPos[2] == 'upright') {
      $cardImgEle3.style.transform = 'translateX(-50%) rotateX(0deg)';
    } else {
      $cardImgEle3.style.transform = 'translateX(-50%) rotateX(180deg)';
    }

    dowithCard == true;
  }

  $ResultContentEle = document.getElementById('resCont');
  if (document.body.clientHeight > document.body.clientWidth) {
    $ResultContentEle.style.transform = 'translate(-50%, 50%)';
  }

  //var $cardBox = document.getElementById("cardshowbox");
  //var boxRect = $cardBox.getBoundingClientRect();

  //setTimeout(() => {
  //showContentType.value = true;
  //}, 200);

  setTimeout(() => {
    //if(getData.value != true)
    //{
    //showAgain.value = true;
    //}
    showAgain.value = true;
  }, 8000);
});

const setCardUpDown = () => {};

const divine = () => {
  //用钱包登录后才可以占卜，(连接钱包)
  if (!proxy.$global.id) {
    divineContent.value = i18n.global.t('divine_disabled');
    showContentType.value = true;
    return false;
  }

  var limit_key = 'divine_limit_' + proxy.$global.id;

  // 检测次数限制
  if (
    process.env.NODE_ENV != 'development' &&
    Number(cookies.get(limit_key)) > 1
  ) {
    divineContent.value = i18n.global.t('divine_limit');
    showContentType.value = true;
    return false;
  }

  //打开等待提示
  setTimeout(() => {
    if (getData.value != true) {
      showWaitMsg.value = true;
    }
  }, 1000);
  //showWaitMsg.value = true;

  http
    .post('/api/index/divine', {
      param: cardsFromParent,
      question: quertionFromParent,
    })
    .then(function (res) {
      getData.value = true;
      // 关闭等待提示
      showWaitMsg.value = false;
      showContentType.value = true;

      divineContent.value = res.data.data.content;
      cookies.set(limit_key, Number(cookies.get(limit_key)) + 1, '1d');
    });
};

const setSrc = (cardIdx) => {
  var cardFromParent = null;
  var $cardImgEle = null;
  var name = '';
  if (cardIdx == 1) {
    name = cardsFromParent.past.name;
    cardFromParent = cardsFromParent.past;
    $cardImgEle = document.getElementById('cardPic1');
    cardsPos[0] = cardFromParent.pos;
  } else if (cardIdx == 2) {
    name = cardsFromParent.present.name;
    cardFromParent = cardsFromParent.present;
    $cardImgEle = document.getElementById('cardPic2');
    cardsPos[1] = cardFromParent.pos;
  } else if (cardIdx == 3) {
    name = cardsFromParent.future.name;
    cardFromParent = cardsFromParent.future;
    $cardImgEle = document.getElementById('cardPic3');
    cardsPos[2] = cardFromParent.pos;
  } else {
    return '';
  }

  var $cardName = name.replaceAll(' ', '_');
  var $cardImgPath = '@\\assets\\res\\' + $cardName + '.png';

  if ($cardImgEle) {
    // 正反处理
    if (cardFromParent.pos == 'reversed') {
      $cardImgEle.style.transform = 'translateX(-50%) rotateX(180deg)';
    } else {
      $cardImgEle.style.transform = 'translateX(-50%) rotateX(0deg)';
    }
    dowithCard = true;
  }
  getCardData = true;

  return require('@/assets/res/' + $cardName + '.png');
};

const handleCloseAgain = () => {
  emit('close');
  Deck.CallCardAgain();
};
</script>

<style lang="less" scoped>
.m-view-result {
  --position: fixed;
  position: relative;
  top: 0;
  left: 0;
  z-index: 9;
  height: 100%;
  width: 100vw;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(30px);

  .result-top {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translate(-50%, -25%);
    width: 228px;
  }

  .card-show {
    position: relative;
    left: 50%;
    width: 700px;
    height: auto;
    top: 0;
    column-width: 150px;
    column-gap: 20px;
    --margin: 0 auto;
    display: flex;
    --justify-content: space-around;

    transform: translate(-50%, 12%);
  }

  .choose-card-ctn {
    position: relative;
    top: 0;
    width: 100%;
    --display: flex;
    flex-direction: column;
    --margin: 0 0;
    line-height: 30px;
  }

  .choose-card {
    position: relative;
    width: 100px;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0%);
  }

  .card-text-up {
    position: relative;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #fef0d5;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.77);
  }

  .card-text-down {
    position: relative;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #fef0d5;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.77);
  }

  .card-video {
    width: 100px;
    position: absolute;
    left: 50%;
    top: 6%;
    transform: translate(-50%, 32%);
  }

  .result-content {
    position: relative;
    width: 971px;
    left: 50%;
    top: 0;
    transform: translate(-50%, 30%);

    .h3 {
      font-size: 29px;
      font-weight: 400;
      color: #fef0d5;
      line-height: 61px;
      text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.77);
      margin-bottom: 59px;
    }

    .content {
      height: 30vh;
      overflow-y: auto;
      font-size: 15px;
      font-weight: 400;
      color: #c7bdaa;
      line-height: 32px;
    }
  }

  .back {
    position: absolute;
    left: 50%;
    bottom: 10%;
    width: 196px;
    height: 44px;
    font-size: 24px;
    font-weight: 400;
    color: #000000;
    margin-top: 31px;
    --float: right;
    transform: translate(-50%, 0);
  }
}

.msg-text {
  position: absolute;
  left: 50%;
  bottom: 40%;
  text-align: center;
  font-size: 28px;
  font-weight: 400;
  color: #fef0d5;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.77);
  transform: translate(-50%, 0);

  animation: breath 1.6s ease-in-out infinite;

  @keyframes breath {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
}

@media screen and (max-width: 768px) {
  .m-view-result {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;

    .result-top {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -27%);
      width: 287px;
    }

    .card-text {
      position: relative;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      color: #fef0d5;
      text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.77);
    }

    .card-video {
      width: 308px;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 32%);
    }

    .result-content {
      width: 86vw;
      left: 50%;
      transform: translate(-50%, 0%);
      top: initial;
      bottom: 10%;
      height: 40vh;

      .h3 {
        font-size: 36px;
        line-height: 61px;
        margin-bottom: 30px;
      }

      .content {
        height: 28vh;
        overflow-y: auto;
        font-size: 18px;
        font-weight: 400;
        color: #c7bdaa;
        line-height: 32px;
      }

      .back {
        width: 245px;
        height: 54px;
        font-size: 30px;
        margin-top: 31px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
}
</style>
