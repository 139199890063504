import { useToast } from "vue-toastification"
import http from '@/utils/http.js'
import { inject } from 'vue'
import { aptosClient } from '@/utils/aptos'
import i18n from '@/i18n'
import {
  getNetwork, switchNetwork,
  getAccount,
  getWalletClient
} from '@wagmi/core'
import { ethers, BrowserProvider, JsonRpcSigner } from 'ethers'
import useWalletsList from "@/store/modules/walletList"

const APTOS_UNIT = Number(10 ** 8) // 1 APT = 10^8 Octa

/**
 * USDT 合约地址
 */
const USDTContractAddressTest = '0xDd2D4aEE8ECc0B1442209A91bbeEE76eD8422AD5'
const USDTContractAddress = '0xdAC17F958D2ee523a2206206994597C13D831ec7'
const contractData = {
  [USDTContractAddressTest]: {
    address: USDTContractAddressTest,
    ABI: [
      { "inputs": [{ "internalType": "address", "name": "to", "type": "address" }, { "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "transfer", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "nonpayable", "type": "function" },
      /**
       * 只读合约可以简写
       */
      'function balanceOf(address who) view returns (uint256)'
    ]
  },
  [USDTContractAddress]: {
    address: USDTContractAddress,
    ABI: [
      { "inputs": [{ "internalType": "address", "name": "to", "type": "address" }, { "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "transfer", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "nonpayable", "type": "function" },
      'function balanceOf(address who) view returns (uint256)',
    ]
  }
}

/**
* @param {string | object} signature 签名信息 petra 为 object。okx 为 string
* @param {Ref<{publicKey: string; address: string}>} account 账户
* @param {string} fullMessage 完整签名消息
* @param {string} walletName 钱包类型
* @param {'Aptos' | undefined} type 钱包类型
*/
export const bindWallet = async (signature, account, fullMessage, walletName, type) => {
  const { getWallet } = useWalletsList()
  if (!signature || !account || !fullMessage) {
    console.log('必填字段缺失', 'signature: ' + signature, 'account: ', account, "fullMessage: " + fullMessage)
    return
  }

  try {
    const toast = useToast()
    const res = await http.post('/api/user/bindWallet', {
      sign:
        typeof signature === 'string'
          ? signature
          : Buffer.from(signature.data.data).toString('base64'),
      publicKey: account.value.publicKey,
      address: account.value.address,
      wallet: walletName,
      message: fullMessage,
      type: type,
    })

    if (res.data.status.code == 10200) {
      toast.success(i18n.global.t('success'))
      getWallet()
      return res.data.status.code
    } else {
      toast.error(res.data.status.msg)
      throw new Error(res.data.status.msg)
    }
  } catch (err) {
    throw new Error(err)
  }
}

export const useWalletUtils = () => {
  const { connected, account, signAndSubmitTransaction, network, changeNetwork } =
    inject('WalletContext')
  const toast = useToast()

  /**
   * @param {string} address
   */
  const assignWallet = (address) => {
    if (!address) return ''

    const start = address.slice(0, 4)
    const end = address.slice(-5)
    return start + '...' + end
  }

  const _bindWallet = bindWallet

  /**
   * aptos 支付
   * @param {string} address 接收地址
   * @param {number | string | bigint} amount apt 数量
   */
  const aptosPay = async (address, amount) => {
    try {
      const transaction = aptosCreateTransaction({
        function: '0x1::aptos_account::transfer',
        typeArguments: [],
        functionArguments: [
          address,
          convertToAptosUnits(amount).toString(),
        ],
        type: "entry_function_payload",
      })
      const trans = await submitTransaction(transaction)
      let hash = typeof trans === 'string' ? trans : trans?.hash

      return hash
    } catch (error) {
      console.error('Failed to connect to wallet:', error)
      throw new Error(error)
    }
  }

  /**
   * aptos create Transaction
   * @example
   * aptosCreateTransaction({
        function: '0x1::aptos_account::transfer',
        typeArguments: [],
        functionArguments: [
          address,
          number,
        ],
      })
   */
  const aptosCreateTransaction = (data) => {
    try {
      if (!connected.value) {
        throw new Error(i18n.global.t('no_bind_wallet'))
      }

      let _network = network.value.name.toLowerCase()
      const NW = process.env.VUE_APP_MODE === 'production' ? 'mainnet' : 'testnet'
      // 发起交易
      if (!_network.includes(NW)) {
        throw new Error(i18n.global.t('aptos_net') + `"${NW}"` + ' network')
      }

      const transaction = {
        data
      }
      return transaction
    }
    catch (error) {
      throw new Error(error)
    }
  }

  function convertToAptosUnits(amount) {
    // 将数字转换为字符串，确保精度不丢失
    const [integerPart, fractionalPart = ""] = amount.toString().split(".")

    // 构建整数表示，APTOS 上 1 APT = 10^8 微单位
    const fractionalPartPadded = (fractionalPart + "00000000").slice(0, 8) // 确保小数部分有 8 位
    const aptosUnitsString = integerPart + fractionalPartPadded

    // 使用 BigInt 进行高精度计算
    return BigInt(aptosUnitsString)
  }

  const submitTransaction = async (transaction) => {
    try {
      const response = await signAndSubmitTransaction(transaction)
      console.log(response, 'response')

      await aptosClient(network.value).waitForTransaction({
        transactionHash: response.hash,
      })

      return response.hash
    } catch (error) {
      console.error(error)
      throw new Error(error)
    }
  }
  /**
   * eth 支付
   * @param {string} address 接收地址
   * @param {number | string | bigint} amount apt 数量
   */
  const ethPay = async (address, amount) => {
    const account = getAccount()

    try {
      // 切换网络;
      const nw = getNetwork()
      if (!nw.chains.find((c) => c.id == nw.chain.id)) {
        await switchNetwork({ chainId: process.env.NODE_ENV !== 'production' ? nw.chains[1].id : nw.chains[0].id })
      }
      const contract = await getContract(process.env.VUE_APP_MODE == 'production' ? USDTContractAddress : USDTContractAddressTest)
      /**
       * 获取余额
       */
      const _amount = ethers.parseUnits(String(amount), 6)
      console.log(contract, account.address)
      const balance = await contract.balanceOf(account.address)

      console.log(balance)
      if (balance < _amount) {
        throw {
          shortMessage: assignWallet(account.address) + ' Wallet address USDT balance is not enough'
        }
      }

      console.log(contract, address, _amount)
      const tx = await contract.transfer(address, _amount)

      await tx.wait()

      return tx
    } catch (err) {
      /**
       * eth 钱包返回的 message 字段为 shortMessage
       */
      console.log(err.shortMessage)
      throw new Error(err.shortMessage)
    }
  }

  /**
 * 获取合约
 * @param {string} contractAddress 合约地址
 */
  const getContract = async (
    contractAddress
  ) => {
    try {
      // const provider = new ethers.BrowserProvider(window.ethereum)
      // await provider.send("eth_requestAccounts", [])  // 请求用户授权连接他们的钱包
      const nw = getNetwork()
      // let signer = await provider.getSigner()
      let signer = await getEthersSigner({
        chainId: nw.chain.id
      })
      const contract = new ethers.Contract(
        contractAddress,
        contractData[contractAddress].ABI,
        signer
      )
      return contract
    } catch (err) {
      throw {
        shortMessage: err.toString()
      }
    }
  }

  return { assignWallet, convertToAptosUnits, bindWallet: _bindWallet, aptosPay, aptosCreateTransaction, submitTransaction, ethPay }
}

export function walletClientToSigner(walletClient) {
  const { account, chain, transport } = walletClient
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  const provider = new BrowserProvider(transport, network)
  const signer = new JsonRpcSigner(provider, account.address)
  return signer
}

/** Action to convert a viem Wallet Client to an ethers.js Signer. */
export async function getEthersSigner({ chainId } = {}) {
  const walletClient = await getWalletClient({ chainId })
  if (!walletClient) return undefined
  return walletClientToSigner(walletClient)
}