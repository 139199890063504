<template>
  <div class="m-view-world">
    <van-button class="world-swiper-next navigation-btn img-btn">
      <img src="@/assets/img/swiper-arrow.png" />
    </van-button>
    <van-button class="world-swiper-pre navigation-btn img-btn"
      ><img src="@/assets/img/swiper-arrow.png"
    /></van-button>
    <div class="swiper swiper-container" id="worldview-swiper">
      <div class="steps">
        <div
          v-for="(item, index) in slideCount - 1"
          :key="index"
          :style="{ left: (100 / slideCount) * (index + 1) + '%' }"
          class="process-node"
        >
          <img :src="stepInactive" v-if="process < (100 / 3) * (index + 1)" />
          <img :src="stepActive" v-else class="process-node_active" />
        </div>

        <div class="step-bg">
          <div class="process-bar" :style="{ width: process + '%' }"></div>
        </div>
      </div>

      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in slideCount" :key="item">
          <!-- <img v-if="isMobile" src="@/assets/img/world/world-view-slide-bg.png" class="world-slide-bg-m" /> -->
          <img
            src="@/assets/img/world/world-view-title.png"
            v-if="isMobile"
            class="world-view-title"
          />
          <img
            :src="
              require(`@/assets/img/world/world-view-${
                item + (isMobile ? '-m' : '')
              }.png`)
            "
            class="world-view-pic"
          />
          <div class="world-view-text">
            <p>{{ worldViewText[active] }}</p>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-scrollbar"></div>
    </div>
  </div>
</template>

<script setup>
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import { nextTick } from 'vue';
import { ref, watch } from 'vue';
import { onMounted } from 'vue';
const stepInactive = require('@/assets/img/step-inactive.png');
const stepActive = require('@/assets/img/step-active.png');
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});

let swiper;

//检测当前页面是否处于活跃状态
watch(
  () => props.active,
  (val) => {
    if (val) {
      startAutoplay();
      // document.addEventListener("mousemove", stopAutoplay);
    } else {
      // document.removeEventListener("mousemove", stopAutoplay);
      swiper?.autoplay.stop();
      // stopProcess();
    }
  },
  {
    immediate: true,
  }
);

let processInterval = null;
const SPEED = 0.125; //平滑系数
const updateProcess = () => {
  if (processInterval) {
    stopProcess();
  }
  processInterval = setInterval(() => {
    if (process.value < 100) {
      process.value = process.value + 1 * SPEED;
    }
  }, 1000 / (100 / 3 / 7) / (1 / SPEED));
};

const stopProcess = () => {
  clearInterval(processInterval);
  processInterval = null;
};

const worldViewText = ref([
  'On Iboas Island lies the land of Castile. During the long years when the Castiles were oppressed by the foreigners, the miracle of the Eye of Light came to a young girl.  The maiden was conceived during the night and gave birth to Sacred son of the Eye of Light, in the early morning. Raised by the maiden, Sacred Sun was able to perform various miracles to heal the sick and wounded, and the people saw him as a saviour as the people of Castalia fought against the alien invasion.',
  'Assemble here and imprint your presence,wandering souls…',
  "Is the world behind the door truly as it appears? Dare to venture into the unknown with us! Embrace the madness and unlock secrets untold. As a bold gesture, here's a free mint for your thoughts. What's your wildest guess?",
]);

let debounceTimmer = null;
function stopAutoplay() {
  swiper?.autoplay.stop();
  stopProcess();
  //防抖
  debounceTimmer = setTimeout(() => {
    if (debounceTimmer) {
      clearTimeout(debounceTimmer);
      debounceTimmer = null;
    }
    startAutoplay();
  }, 2000);
}

function startAutoplay() {
  updateProcess();
  swiper?.autoplay.start();
}

const swiperRef = ref();

const slideCount = 3;
const active = ref(0);
const process = ref(0);

const isMobile = ref(false);
onMounted(() => {
  isMobile.value = document.body.clientWidth <= 768;
  swiper = new Swiper('#worldview-swiper', {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 1,
    speed: 400,
    autoplay: {
      delay: 7000,
      disableOnInteraction: false,
      stopOnLastSlide: true,
    },
    loop: true,
    navigation: {
      nextEl: '.world-swiper-next',
      prevEl: '.world-swiper-pre',
    },
    on: {
      slideChange: function () {
        active.value = swiper.realIndex;
        stopProcess();
        process.value = swiper.realIndex * (100 / slideCount);
        nextTick(() => {
          updateProcess();
        });
      },
    },
  });
  swiper.autoplay.stop();
});
</script>

<style lang="less">
.m-view-world {
  position: relative;
  flex-grow: 1;
  width: 100vw;
  height: 100%;

  .steps {
    position: absolute;
    bottom: 8.43%;
    left: 50%;
    transform: translateX(-50%);
    width: 1190px;
    z-index: 2;

    .process-node {
      position: absolute;
      transform: translate(-50%, -46%);
      display: flex;
      align-items: center;

      img {
        width: 27px;
        height: 27px;
      }

      .process-node_active {
        width: 103px;
        height: 103px;
      }
    }

    .step-bg {
      width: 100%;
      height: 7px;
      background-color: initial;
      background-image: url('@/assets/img/step-bg@2x.png');
      background-repeat: no-repeat;
      background-size: 100% auto;
      display: flex;
      align-items: center;

      .process-bar {
        height: 2px;
        box-shadow: 0px 0px 30px 5px rgba(246, 233, 176, 0.28);
        transition: width 0.1s ease-in-out;
        background: linear-gradient(
          90deg,
          rgba(246, 233, 176, 0) 0%,
          rgba(246, 233, 176, 0.2) 5%,
          rgba(246, 233, 176, 1) 15%,
          rgba(246, 233, 176, 1) 90%,
          rgba(246, 233, 176, 0.2) 100%
        );
      }
    }
  }

  #worldview-swiper {
    height: 100%;

    .swiper-slide {
      width: 100vw;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;

      .world-view-text {
        position: absolute;
        bottom: 15%;
        height: fit-content;
        width: 80%;
        z-index: 1;
        font-size: 21px;
        font-weight: 400;
        color: #ede7dc;
        line-height: 46px;
        text-shadow: 0px 2px 3px #000000;
      }
    }
  }

  .world-view-pic {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .navigation-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;

    img {
      width: 72px;
    }

    &:hover {
      img {
        width: 80px;
      }
    }
  }

  .world-swiper-next {
    right: 54px;
    transform: rotateY(180deg) translateY(-50%);
  }

  .world-swiper-pre {
    left: 54px;
  }
}

@media screen and (max-width: 768px) {
  .m-view-world {
    overflow: hidden;
    background-image: url('@/assets/img/world/world-view-bg.png');
    background-size: 100% auto;
    background-position-y: center;
    // padding-top: 80px;
    .steps {
      bottom: 8.43%;
      width: 100vw;
      .process-node {
        img {
          width: 30px;
          height: 30px;
        }
        .process-node_active {
          width: 115px;
          height: 114px;
        }
      }
      .step-bg {
        .process-bar {
        }
      }
    }

    #worldview-swiper {
      height: 100%;

      .world-view-title {
        width: 82vw;
        position: relative;
        // top: 0;
        // left: 50%;
        transform: translate(0, -200%);
      }

      .swiper-wrapper {
        display: flex;
        align-items: center;
      }

      .swiper-slide {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: fit-content;
        min-height: 60%;
        &::after {
          content: '';
          background-image: url('@/assets/img/world/world-view-slide-bg.png');
          position: absolute;
          width: 613px;
          height: 278px;
          left: 50%;
          top: 0;
          transform: translate(-50%, -73%);
          z-index: 0;
        }

        .world-view-pic {
          width: 85%;
          position: relative;
          margin-bottom: 40px;
          z-index: 1;
        }

        .world-view-text {
          position: relative;
          top: initial;
          bottom: initial;
          width: 85%;
          font-size: 20px;
          line-height: 40px;
          text-shadow: 0px 1px 2px #000000;
        }
      }
    }

    .navigation-btn {
      display: none;
    }

    .world-swiper-next {
      right: 54px;
      transform: rotateY(180deg) translateY(-50%);
    }

    .world-swiper-pre {
      left: 54px;
    }
  }
}
</style>
