import { defineStore } from "pinia"
import http from '@/utils/http.js'
import { ref, onMounted } from 'vue'

const useWalletsList = defineStore("walletsList", () => {
  const walletsList = ref([])

  const getWallet = () => {
    http.post('/api/user/getWallet').then(function (res) {
      if (!res) return false

      if (res.data.data) {
        walletsList.value = res.data.data.map((i) => {
          i.address_org = i.address
          let start = i.address.slice(0, 4)
          let end = i.address.slice(-6)
          i.address = start + '***' + end
          return i
        })
      }
    })
  }

  return {
    walletsList,
    getWallet
  }
})

export default useWalletsList
