<template>
  <Dialog
    :show="show"
    @close="$emit('close')"
    :background-image="require('@/assets/img/dialog-bg-3.png')"
  >
    <div class="congrats-wrapper">
      <p class="title">Set up wallet certification</p>
      <p v-if="wallet" class="content">wallet address [{{ wallet }}]</p>
      <div v-else class="content">
        <van-button @click="connect">connect wallect</van-button>
      </div>

      <div v-if="wallet" class="congrats-dialog-footer">
        <van-button @click="notNow">Not now</van-button>
        <van-button @click="bind">Bind</van-button>
      </div>
      <br />
    </div>
  </Dialog>

  <Modal v-if="modalStatus" @close="closeModal()"></Modal>
</template>

<script setup>
import { ref, onMounted, toRefs } from 'vue';
import { useWeb3Modal } from '@web3modal/wagmi/vue';
import { useRouter, useRoute } from 'vue-router';
import Modal from '@/views/dialogs/Modal.vue';
import useGlobal from '@/store/modules/global';
import Dialog from '@/components/Dialog.vue';
import { useToast } from 'vue-toastification';
import useModal from '@/store/modules/modal';
import { signMessage } from '@wagmi/core';
import http from '@/utils/http.js';
import i18n from '@/i18n';
import { bindWallet } from '@/hooks/useWalletUtils';

const emit = defineEmits(['close']);
const { open } = useWeb3Modal();
const modalStatus = ref(false);
const global = useGlobal();
const router = useRouter();
const route = useRoute();
const toast = useToast();
const modal = useModal();

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  address: {
    type: String,
    require: true,
  },
});
const { show } = toRefs(props);
const wallet = ref('');

onMounted(() => {
  if (props.address) {
    const start = props.address.slice(0, 4);
    const end = props.address.slice(-6);
    wallet.value = start + '***' + end;
  }
});

const connect = () => {
  open();
  emit('close');
};

const notNow = () => {
  global.notNow = true;
  emit('close');
};

const closeModal = () => {
  emit('close');
  modalStatus.value = false;
  if (route.name == 'wallet' || route.name == 'account') {
    window.location.reload();
  }
};

const bind = async () => {
  const message =
    'Welcome to the Castile platform’s wallet management process. By clicking to sign in, you are initiating the linking of your wallet address to your Castile account as a deposit wallet. This request will not trigger a blockchain transaction or cost any gas fees.';
  const sign = await signMessage({
    message: message,
  });

  console.log(sign);

  await bindWallet(
    sign,
    {
      value: {
        address: props.address,
        publickey: props.address,
      },
    },
    message
  );
};
</script>

<style lang="less" scoped>
.m-view-congrats {
  width: 100vw;
  height: 100%;
  background-image: url('@/assets/img/dialog-bg.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.congrats-wrapper {
  width: 574px;
  padding: 30px;
  box-sizing: content-box;
  .title {
    font-size: 30px;
    font-weight: 400;
    color: #d8d5d0;
    background: linear-gradient(0deg, #f9f6c3 0%, #e7a952 100%);
    margin-top: 20px;
    margin-bottom: 38px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }
  .content {
    line-height: 30px;
    font-family: SourceHanSerif;
    font-weight: 500;
    color: #d8d5d0;
    font-size: 22px;
    margin-bottom: 12px;
    text-align: center;
  }
}
.congrats-dialog-footer {
  display: flex;
  margin-top: 50px;
  justify-content: space-around;
  .van-button {
    width: 241px;
    font-size: 20px;
    padding: 0;
  }
}
</style>
