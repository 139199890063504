<template>
  <van-dialog
    v-model:show="props.show"
    title=""
    show-cancel-button
    class-name="my-dialog-guest"
    :showConfirmButton="false"
    :showCancelButton="false"
    :closeOnClickOverlay="true"
    :beforeClose="handleClose"
    v-bind="$attrs"
  >
    <van-button class="close-guest-btn img-btn" @click="handleClose">
      <img src="@/assets/img/dialog-close-icon.png" />
    </van-button>

    <div class="dialog-content">
      <slot></slot>
    </div>
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </van-dialog>
</template>

<script setup>
import { ref } from 'vue';

const emit = defineEmits(['close']);

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const handleClose = () => {
  emit('close');
};
</script>

<style lang="less">
#app .my-dialog-guest {
  box-sizing: border-box;
  width: fit-content;
  height: fit-content;
  background-color: #000;
  border: 8px solid #24272a;
  border-radius: 0px;

  .close-guest-btn {
    position: absolute;
    right: 6px;
    top: 0px;
    img {
      height: 24px;
      width: 24px;
    }
  }

  .congrats-wrapper {
    padding: 22px;
  }
  .congrats-wrapper .title {
    font-size: 30px;
  }

  .dialog-title-logo {
    width: 222px;
    margin-bottom: 33px;
  }
  .dialog-content {
    font-family: SourceHanSerif;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
  }
}
.van-overlay {
  background: rgba(0, 0, 0, 0.8) !important;
}
</style>
