<template>
  <slot></slot>
</template>

<script setup>
import { ref, onBeforeMount, provide, watch } from 'vue';
// import { groupAndSortWallets } from '@aptos-labs/wallet-adapter-core';
import { WalletCore } from '@aptos-labs/wallet-adapter-core';
import { BitgetWallet } from '@bitget-wallet/aptos-wallet-adapter';
import { MartianWallet } from '@martianwallet/aptos-wallet-adapter';
// import { MSafeWalletAdapter } from '@msafe/aptos-wallet-adapter';
import { OKXWallet } from '@okwallet/aptos-wallet-adapter';
import { PontemWallet } from '@pontem/wallet-adapter-plugin';
// import { TrustWallet } from '@trustwallet/aptos-wallet-adapter';
import { FewchaWallet } from 'fewcha-plugin-wallet-adapter';
import { Network } from '@aptos-labs/ts-sdk';
import { useToast } from 'vue-toastification';
import { debounce } from '@/utils/debounce-throttle';
import { bindWallet } from '@/hooks/useWalletUtils';
import http from '@/utils/http';

const props = defineProps({
  optInWallets: {
    type: Array,
    default: () => [],
  },
});

const account = ref(null);
const network = ref(null);
const connected = ref(false);
const wallet = ref(null);
const isLoading = ref(true);
let walletCore = null;
const defaultWallets = [
  new BitgetWallet(),
  new FewchaWallet(),
  new MartianWallet(),
  new PontemWallet(),
  new OKXWallet(),
];
const wallets = ref([...defaultWallets]);
const toast = useToast();

const setState = (newState) => {
  account.value =
    typeof newState.account === 'undefined' ? account.value : newState.account;
  network.value =
    typeof newState.network === 'undefined' ? network.value : newState.network;
  connected.value =
    typeof newState.connected === 'undefined'
      ? connected.value
      : newState.connected;
  wallet.value =
    typeof newState.wallet === 'undefined' ? wallet.value : newState.wallet;
};

const onError = (funName, error) => {
  console.log(funName, '函数触发', error);

  if (error == 'WalletNotConnectedError') {
    toast.error(
      'Wallet failed to obtain, please refresh the page and try again'
    );
    return;
  }

  if (error?.toString().indexOf('Account not found by Address') >= 0) {
    toast.error('Insufficient account balance');
    return;
  }

  toast.error(error.message || error || 'Unknown wallet error');
};

const connect = async (walletName) => {
  try {
    isLoading.value = true;
    await walletCore?.connect(walletName);
  } catch (error) {
    if (onError) onError('connect', error);
    return Promise.reject(error);
  } finally {
    isLoading.value = false;
  }
};

const disconnect = async () => {
  try {
    if (connected.value) {
      await walletCore?.disconnect();
    }
  } catch (error) {
    if (onError) onError('disconnect', error);
    return Promise.reject(error);
  }
};

const signTransaction = async (transaction, asFeePayer, options) => {
  if (!walletCore) {
    throw new Error('WalletCore is not initialized');
  }
  try {
    return await walletCore?.signTransaction(transaction, asFeePayer, options);
  } catch (error) {
    if (onError) onError('signTransaction', error);
    return Promise.reject(error);
  }
};

const signMessage = async (payload) => {
  if (!walletCore) {
    throw new Error('WalletCore is not initialized');
  }
  try {
    const sign = await walletCore?.signMessage(payload);
    if (!sign) {
      throw new Error('Abnormal signature message data, please try again.');
    }
    return sign;
  } catch (error) {
    disconnect();
    if (onError) onError('signMessage', error);
    return Promise.reject(error);
  }
};

const signMessageAndVerify = async (payload) => {
  if (!walletCore) {
    throw new Error('WalletCore is not initialized');
  }
  try {
    return await walletCore?.signMessageAndVerify(payload);
  } catch (error) {
    if (onError) onError('signMessageAndVerify', error);
    return Promise.reject(error);
  }
};

const submitTransaction = async (transaction) => {
  if (!walletCore) {
    throw new Error('WalletCore is not initialized');
  }
  try {
    return await walletCore?.submitTransaction(transaction);
  } catch (error) {
    if (onError) onError('submitTransaction', error);
    return Promise.reject(error);
  }
};

const signAndSubmitTransaction = async (transaction) => {
  try {
    let provider = walletCore;
    /**
     * 兼容旧标准
     */
    if (['OKX Wallet', 'Bitget Wallet'].includes(walletCore._wallet.name)) {
      provider =
        walletCore?._wallet?.provider?.aptos ||
        walletCore?._wallet?.provider?.okxProvider?.aptos;

      console.log('------------------------');
      // provider = window.okxwallet.aptos;
      transaction = {
        arguments: transaction.data.functionArguments,
        function: transaction.data.function,
        type: transaction.data.type,
        type_arguments: transaction.data.typeArguments,
      };
    }

    return await provider?.signAndSubmitTransaction(transaction);
  } catch (error) {
    if (onError) onError('signAndSubmitTransaction', error);
    return Promise.reject(error);
  }
};

const changeNetwork = async (network) => {
  if (!walletCore) {
    throw new Error('WalletCore is not initialized');
  }
  try {
    return await walletCore?.changeNetwork(network);
  } catch (error) {
    if (onError) onError('changeNetwork', error);
    return Promise.reject(error);
  }
};

const handleConnect = () => {
  setState({
    connected: true,
    account: walletCore?.account || null,
    network: walletCore?.network || null,
    wallet: walletCore?.wallet || null,
  });
};

const handleDisconnect = () => {
  console.log('断开');
  if (!connected.value) return;
  setState({
    connected: false,
    account: walletCore?.account || null,
    network: walletCore?.network || null,
    wallet: null,
  });
};

const handleAccountChange = () => {
  debounce(async () => {
    if (!connected.value) return;
    if (!walletCore?.wallet) return;
    setState({
      account: walletCore?.account || null,
    });

    const bindStatusRes = await http.post('/api/user/getBindStatus', {
      address: walletCore?.account?.address,
    });

    if (bindStatusRes?.data?.status?.code === 10200) {
      return;
    }

    const payload = {
      message:
        'Welcome to the Castile platform’s wallet management process. By clicking to sign in, you are initiating the linking of your wallet address to your Castile account as a deposit wallet. This request will not trigger a blockchain transaction or cost any gas fees.',
      nonce: Date.now(),
    };
    try {
      let sign = await signMessage(payload);
      // console.log({
      //   sign:
      //     typeof sign.signature === 'string'
      //       ? sign.signature
      //       : Buffer.from(sign.signature.data.data).toString('base64'),
      //   publicKey: walletCore?.account?.publicKey,
      //   address: walletCore?.account?.address,
      //   wallet: wallet.value.name,
      //   message: sign.fullMessage,
      //   type: 'Aptos',
      // });
      if (!sign) return;

      let signature, fullMessage;
      if (wallet.value.name.indexOf('Pontem') != -1) {
        signature = Buffer.from(sign.result.signature).toString('base64');
        fullMessage = sign.result.fullMessage;
      } else {
        signature = sign.signature;
        fullMessage = sign.fullMessage;
      }

      const data = await bindWallet(
        signature,
        account,
        fullMessage,
        wallet.value.name,
        'Aptos'
      );
      console.log(data);
    } catch (err) {
      console.log(err);
      disconnect();
    }
  });
};

const handleNetworkChange = () => {
  if (!connected.value) return;
  if (!walletCore?.wallet) return;
  setState({
    network: walletCore?.network || null,
  });
};

const handleReadyStateChange = (updatedWallet) => {
  const updatedWallets = wallets.value.map((wallet) => {
    if (wallet.name === updatedWallet.name) {
      return { ...wallet, readyState: updatedWallet.readyState };
    }
    return wallet;
  });
  wallets.value = updatedWallets;
};

const handleStandardWalletsAdded = (standardWallet) => {
  const existingWalletIndex = wallets.value.findIndex(
    (wallet) => wallet.name === standardWallet?.name
  );
  if (existingWalletIndex !== -1) {
    wallets.value = [
      ...wallets.value.slice(0, existingWalletIndex),
      standardWallet,
      ...wallets.value.slice(existingWalletIndex + 1),
    ];
  } else {
    wallets.value = [...wallets.value, standardWallet];
  }
};

onBeforeMount(() => {
  const core = new WalletCore(wallets.value, props.optInWallets ?? [], {
    network: Network.MAINNET,
    // aptosConnectDappId: '57fa42a9-29c6-4f1e-939c-4eefa36d9ff5',
    mizuwallet: {
      manifestURL:
        'https://assets.mz.xyz/static/config/mizuwallet-connect-manifest.json',
    },
  });
  walletCore = core;
  wallets.value = core.wallets ?? [];
});

watch(connected, () => {
  if (connected.value) {
    walletCore?.onAccountChange();
    walletCore?.onNetworkChange();
  }
});

watch([() => wallets.value, () => account.value], () => {
  walletCore?.off('connect', handleConnect);
  walletCore?.off('disconnect', handleDisconnect);
  walletCore?.off('accountChange', handleAccountChange);
  walletCore?.off('networkChange', handleNetworkChange);
  walletCore?.off('readyStateChange', handleReadyStateChange);
  walletCore?.off('standardWalletsAdded', handleStandardWalletsAdded);

  walletCore?.on('connect', handleConnect);
  walletCore?.on('disconnect', handleDisconnect);
  walletCore?.on('accountChange', handleAccountChange);
  walletCore?.on('networkChange', handleNetworkChange);
  walletCore?.on('readyStateChange', handleReadyStateChange);
  walletCore?.on('standardWalletsAdded', handleStandardWalletsAdded);
});

provide('WalletContext', {
  connect,
  account,
  network,
  connected,
  disconnect,
  wallet,
  wallets,
  signAndSubmitTransaction,
  signTransaction,
  signMessage,
  signMessageAndVerify,
  isLoading,
  submitTransaction,
  changeNetwork,
  defaultWallets,
});
</script>
